<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList3 } from '@/utils/textFile'
import apiTool from '@/command/apiTool'

export default {
  name: 'scenicAreasTickets',
  data() {
    return {
      ...api.command.getState(),
      myset: null,
    }
  },
  mounted() {
    this.getInit()
  },
  destroyed() {
    clearInterval(this.myset)
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/ticketOrder/page',
        current: 1,
      })
      //   .then(result => {
      //     console.log(this.records)
      //     for (let i in this.records) {
      //       if (this.records[i].status === '5') {
      //         this.records[i].countDownTime = ''
      //         this.records[i].endTime = '2022-11-13 19:06:36'
      //         // this.countDown(i)
      //         console.log(i, this.records[i])
      //       }
      //     }
      //   })
    },
    //倒计时
    countDownFun(time) {
      let startTime = new Date() //当前时间
      let end = new Date(time) //结束时间
      // console.log(end)
      let result = parseInt((end - startTime) / 1000) //计算出豪秒
      let d = parseInt(result / (24 * 60 * 60)) //用总共的秒数除以1天的秒数
      let h = parseInt((result / (60 * 60)) % 24) //精确小时，用去余
      let m = parseInt((result / 60) % 60) //剩余分钟就是用1小时等于60分钟进行趣余
      let s = parseInt(result % 60)
      //当倒计时结束时，改变内容
      if (result <= 0) {
        // 重新请求列表
        return ''
      }
      if (h < 10) {
        h = '0' + h
      }
      if (s < 10) {
        s = '0' + s
      }
      if (h == 0 && m == 0) {
        return s
      } else if (h == 0) {
        return m + ':' + s
      } else if (d == 0) {
        return h + ':' + m + ':' + s
      } else {
        return d + ':' + h + ':' + m + ':' + s
      }
    },
    // 页面多个倒计时 归零时清除
    countDown(i) {
      let that = this
      let item = that.records[i]
      that.records[i].countDownFn = setInterval(() => {
        if (that.countDownFun(item.countDownTime) == '倒计时结束') {
          clearInterval(that.records[i].countDownFn) //清除定时器
        } else {
          item.countDownTime = that.countDownFun(item.endTime)
          that.$set(that.list, item.countDownTime, that.countDownFun(item.endTime))
          that.$forceUpdate()
        }
      }, 1000)
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入购买人电话',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName',
        },
      ]
    },
    // 获取选中订单详情
    getDetailData(orderId, orderItemId) {
      return new Promise((resolve, reject) => {
        getAction(`/api/ticketOrder/detail?orderId=${orderId}&orderItemId=${orderItemId}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record,
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/farming/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => {
                  return <div class="product-order-item">{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '总价',
          align: 'left',
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          },
        },

        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          align: 'left',
          customRender: (text) => (orderStateList3.find((e) => e.value == text) || { text: '' }).text,
          onExport: (text) => (orderStateList3.find((e) => e.value == text) || { text: '' }).text,
          filterMultiple: false,
          filters: orderStateList3,
        },
        {
          dataIndex: 'buyPhone',
          title: '电话',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          customRender: function customRender(text, records) {
            return (
              <div class="actionBox">
                {records.orderItemList.map((e, index) => {
                  // 获取对应button按钮 子订单需要判断是否有退款
                  let data = that.getColumnsButtonData({
                    parent: records,
                    item: e,
                    index,
                    childrenId: e.id,
                  })
                  return (
                    <div class="product-order-item">
                      {data.map((el) => that.renderColumnButton({ orderItem: e, item: el }))}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
      ]
    },
    getColumnsButtonData({ parent, item, index, childrenId } = {}) {
      let arr = [
        {
          name: '查看订单',
          display: true,
          onClick: () => this.onRowSelect(parent.id, item.id),
        },
        {
          type: 'pop',
          popTitle: '确认关闭吗?',
          name: '关闭订单',
          display: parent.status == '0',
          onClick: () => this.onBatchDelete([parent]),
        },
      ]
      if (process.env.NODE_ENV === 'development') {
        return arr.filter((e) => e.display)
      } else {
        const buttonName = apiTool.getButtonName()
        return arr.filter((e) => e.display && buttonName.some((el) => e.name.indexOf(el) !== -1))
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },
    closeOrder(records) {},
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    onRowSelect(orderId, orderItemId) {
      const ext = [
        // 表格
        orderConfig.tableTickets,
        // 可选择表格
        orderConfig.tableTicketsSelect,
        // 基础信息
        orderConfig.baseTicket,
        // 用户表格
        orderConfig.tableTicketsInfo,
        // 第三方票务
        orderConfig.thirdPartyTicket,
        // 记录
        orderConfig.recordTicket,
      ]
      this.getDetailData(orderId, orderItemId).then((e) => {
        orderConfig.open({
          data: e,
          params: { data: e, customOrderId: orderId, customOrderItemId: orderItemId },
          executeFun: ext,
          onHidden: () => {
            this.getInit()
          },
          foot: ({ close, submit }) => {
            return [
              {
                name: '取消',
                onClick: close,
              },
            ]
          },
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.actionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
